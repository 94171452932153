import AppButton from '@/components/AppButton';
import { GTMContext } from '@/context/GTMContext';
import useAccountStore from '@/store/useAccountStore';
import useModalStore from '@/store/useModalStore';
import { gtmEvent_cancel_sign_out, gtmEvent_yes_sign_out } from '@/utils/GTMFunctions';
import { useContext } from 'react';
import Modal from '..';

// LogoutModal component
const LogoutModal = (props) => {
  // destructuring props

  // context hooks
  const setLogOut = useModalStore((state) => state.setLogOut);
  const logOutAccount = useAccountStore((state) => state.logOutAccount);
  const { setDataLayer } = useContext(GTMContext);

  // state

  // effect

  // queries

  // other variables/functions/handlers

  const handleLogOut = async () => {
    setDataLayer(gtmEvent_yes_sign_out());

    await logOutAccount();
  };

  // render
  return (
    <Modal {...props}>
      <div className="login-successful">
        <div className="login-successful-title">Disconnect Account</div>
        <div className="login-successful-content">Disconnecting account from Grapes. Are you sure?</div>
        <div className="login-successful-buttons">
          <AppButton
            variant="outline"
            className="login-successful-button"
            size="sm"
            onClick={() => {
              setDataLayer(gtmEvent_cancel_sign_out());
              setLogOut(false);
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            variant="purple"
            className="login-successful-button"
            onClick={async () => {
              await handleLogOut();
            }}
          >
            YES
          </AppButton>
        </div>
      </div>
    </Modal>
  );
};

// export
export default LogoutModal;
