import { utils } from 'ethers';

const weiDecimals = 18;
const usdcDecimals = 6;
const usdcPower = 10 ** 6;
const weiPower = 10 ** 6;
const maxApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const confirmations = 2;
const power = 10 ** 6;

const CHAIN = {
  TESTNET: 'https://rpc.ankr.com/bsc_testnet_chapel',
  MAINNET: 'https://rpc.ankr.com/bsc',
};

const WALLET = {
  COINBASE: 'https://wallet.coinbase.com/',
  METAMASK: 'https://metamask.io',
};

function weiToEtherWithDecimals(weiAmount, decimalPlaces = 18) {
  const ether = utils.formatUnits(weiAmount, decimalPlaces);
  return ether.toString();
}

function etherToWeiWithDecimals(etherAmount, decimalPlaces = 18) {
  const wei = utils.parseUnits(etherAmount, decimalPlaces);
  return wei.toString();
}

export {
  CHAIN,
  WALLET,
  confirmations,
  etherToWeiWithDecimals,
  maxApprove,
  usdcDecimals,
  usdcPower,
  weiDecimals,
  weiPower,
  weiToEtherWithDecimals,
};
