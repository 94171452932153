/* eslint-disable import/no-anonymous-default-export */
import { http } from '../http';

export default {
  getChallenge(walletAddress) {
    return http.get(`/user/challenge/${walletAddress}`);
  },
  login(challenge, signature, walletAddr, publicKey) {
    return http.post(
      '/user/login',
      JSON.stringify({
        signature,
        challenge,
        wallet_addr: walletAddr,
        type: 'ETH',
        public_key: publicKey,
      })
    );
  },
  logout() {
    return http.get('/user/logout');
  },
  me() {
    return http.get('/user/me');
  },
  getBalance(ident_type, ident_value) {
    return http.post(
      'unicorn/user/balance',
      JSON.stringify({
        ident_type,
        ident_value,
      })
    );
  },
  getExchangeRate({ currency }) {
    return http.get(`/unicorn/exr/USD/${currency}`);
  },
  deposit(ident_value, amount, txn_id, selectedGameId, currency, chain) {
    return http.post(
      'unicorn/user/balance/deposit',
      JSON.stringify({
        ident_type: 'web3auth_binance',
        ident_value,
        currency,
        amount,
        txn_id,
        blockchain: chain,
      }),
      {
        headers: {
          'X-App-ID': selectedGameId,
        },
      }
    );
  },
  withdraw(ident_type, ident_value, currency, amount, nominatedAddress, selectedGameId, chain) {
    return http.post(
      'unicorn/user/balance/withdraw',
      JSON.stringify({
        ident_type,
        ident_value,
        currency,
        amount: parseFloat(amount).toFixed(2),
        nominated_address: nominatedAddress,
        blockchain: chain,
      }),
      {
        headers: {
          'X-App-ID': selectedGameId,
        },
      }
    );
  },
  getTransactionsHistory(ident_type, ident_value) {
    return http.post(
      'unicorn/user/tx-history',
      JSON.stringify({
        ident_type,
        ident_value,
      })
    );
  },
  getTransaction() {
    return http.get(`/unicorn/tx`);
  },
  setTransaction(id, status) {
    return http.post(
      `/unicorn/tx`,
      JSON.stringify({
        txn_id: id,
        approve: status,
      })
    );
  },
  getDepositAmounts() {
    return http.get('/unicorn/deposit/prices');
  },
  getApps() {
    return http.get('/unicorn/project/apps');
  },
  getCurrencies() {
    return http.get('/unicorn/apps/currencies');
  },
  getUserCurrencies(ident_type, ident_value) {
    return http.post(
      '/unicorn/user/currencies',
      JSON.stringify({
        ident_type,
        ident_value,
      })
    );
  },
  getChainConfigs() {
    return http.get('/chain/configs');
  },
};
