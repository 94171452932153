import { allowance, approve, balanceOf, deposit } from '@/utils/service/contracts';
import HansoloService from '@/utils/service/hansolo';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { create } from 'zustand';
import useAccountStore from '../useAccountStore';
import useLoadingStore from '../useLoadingStore';
import useModalStore from '../useModalStore';

const useWalletStore = create((set, get) => {
  const setWithdrawError = useModalStore.getState().setWithdrawError;
  const setCompletedCryptoWithdraw = useModalStore.getState().setCompletedCryptoWithdraw;
  const setLoginWalletError = useModalStore.getState().setLoginWalletError;
  const setProcessing = useLoadingStore.getState().setProcessing;
  const setDepositError = useModalStore.getState().setDepositError;
  const setShowUserNotRegistered = useModalStore.getState().setShowUserNotRegistered;
  const userNotRegistered = useModalStore.getState().userNotRegistered;
  const setWithdrawToManyRequest = useModalStore.getState().setWithdrawToManyRequest;
  return {
    wallet: null,
    CryptoWalletAddress: null,
    depositAmount: null,
    chainConfig: null,
    walletProvider: null,
    setWalletProvider: (walletProvider) => set({ walletProvider }),
    setChainConfig: (chainConfig) => set({ chainConfig }),
    setDepositAmount: (depositAmount) => set({ depositAmount }),
    setCryptoWalletAddress: (CryptoWalletAddress) => set({ CryptoWalletAddress }),
    setWallet: (wallet) => set({ wallet }),
    depositCrypto: async (amount) => {
      const setBalanceUpdated = useAccountStore.getState().setBalanceUpdated;
      const setCompletedCryptoDepositAmount = useModalStore.getState().setCompletedCryptoDepositAmount;
      const setLowBalanceError = useModalStore.getState().setLowBalanceError;
      const selectedGameId = useAccountStore.getState().selectedGameId;
      const selectedCurrency = useAccountStore.getState().selectedCurrency;
      const setIsUserDeposited = useAccountStore.getState().setIsUserDeposited;
      const chainConfig = get().chainConfig;
      const getBalance = useAccountStore.getState().getBalance;
      if (userNotRegistered) {
        setShowUserNotRegistered(true);
        setProcessing(false);
        return;
      }
      await getBalance();
      setProcessing(true);
      try {
        const CryptoWalletAddress = get().CryptoWalletAddress;
        const walletAddress = useAccountStore.getState().walletAddress;
        const walletProvider = get().walletProvider;
        let foundPlatformName = null;
        Object.keys(chainConfig.tokens).forEach((platform) => {
          if (chainConfig.tokens[platform][selectedCurrency]) {
            foundPlatformName = platform;
          }
        });
        setTimeout(() => {
          setProcessing(false);
        }, 35000);
        const cryptoBalance = await balanceOf(
          chainConfig.tokens[foundPlatformName][selectedCurrency].address,
          chainConfig.abis.balanceOf,
          chainConfig.chains[foundPlatformName].rpc_url,
          CryptoWalletAddress,
          chainConfig.tokens[foundPlatformName][selectedCurrency].decimals
        );
        if (Number(amount) > Number(cryptoBalance)) {
          setLowBalanceError(true);
          throw new Error('Crypto Balance too low');
        }
        const allowanceCheck = await allowance(
          chainConfig.tokens[foundPlatformName][selectedCurrency].address,
          chainConfig.abis.allowance,
          chainConfig.chains[foundPlatformName].rpc_url,
          CryptoWalletAddress,
          chainConfig.banks[foundPlatformName],
          chainConfig.tokens[foundPlatformName][selectedCurrency].decimals
        );
        if (!allowanceCheck || !(allowanceCheck > 0)) {
          await approve(
            chainConfig.tokens[foundPlatformName][selectedCurrency].address,
            chainConfig.abis.approve,
            walletProvider,
            chainConfig.banks[foundPlatformName],
            chainConfig.tokens[foundPlatformName][selectedCurrency].approval_amount,
            chainConfig.tokens[foundPlatformName][selectedCurrency].decimals
          );
        }
        const tx = await deposit(
          chainConfig.abis.deposit,
          chainConfig.banks[foundPlatformName],
          walletProvider,
          amount,
          walletAddress,
          chainConfig.tokens[foundPlatformName][selectedCurrency].address,
          chainConfig.tokens[foundPlatformName][selectedCurrency].decimals
        );
        const response = await HansoloService.deposit(
          walletAddress,
          amount.toString(),
          tx.transactionHash,
          selectedGameId.app,
          selectedCurrency,
          foundPlatformName
        );
        setCompletedCryptoDepositAmount(amount);
        setBalanceUpdated(false);
        setIsUserDeposited(true);
      } catch (error) {
        setProcessing(false);
        setDepositError(true);
        console.log('Error :', error);
      }
      setProcessing(false);
    },
    CryptoWithdrawal: async (amount) => {
      const selectedGameId = useAccountStore.getState().selectedGameId;
      const selectedCurrency = useAccountStore.getState().selectedCurrency;
      const CryptoWalletAddress = get().CryptoWalletAddress;
      const getBalance = useAccountStore.getState().getBalance;
      const chainConfig = get().chainConfig;
      const selectedChainName = useAccountStore.getState().selectedChainName;
      try {
        const walletAddress = useAccountStore.getState().walletAddress;
        const nominatedAddress = CryptoWalletAddress;
        const response = await HansoloService.withdraw(
          'web3auth_binance',
          walletAddress,
          selectedCurrency,
          amount,
          nominatedAddress,
          selectedGameId.app,
          selectedChainName
        );
        setCompletedCryptoWithdraw(true);
        getBalance();
      } catch (error) {
        if (error?.response?.status === 409) {
          setWithdrawToManyRequest(true);
          setWithdrawError(true);
        }
        setWithdrawError(true);
        console.log('Error :', error);
      }
    },
    getChainConfig: async () => {
      try {
        const setChainConfig = get().setChainConfig;
        const response = await HansoloService.getChainConfigs();
        setChainConfig(response?.data?.data);
        return response;
      } catch (error) {
        console.log('Error :', error);
      }
    },
    walletLogin: async (wallets, walletAddress) => {
      const setCryptoWalletAddress = get().setCryptoWalletAddress;
      try {
        setCryptoWalletAddress(toChecksumAddress(walletAddress));
      } catch (error) {
        setLoginWalletError(true);
        console.log('Error :', error);
      }
    },
  };
});

export default useWalletStore;
