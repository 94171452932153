import coinbaseModule from '@web3-onboard/coinbase';
import injectedModule from '@web3-onboard/injected-wallets';
import { init } from '@web3-onboard/react';

import walletConnectModule from '@web3-onboard/walletconnect';

import { CHAIN } from '@/utils/helpers';

//New version requires modules to be imported for each wallet
const injected = injectedModule();
const coinbase = coinbaseModule();
const walletConnect = walletConnectModule({
  projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_ID,
  version: 2,
  handleUri: (uri) => console.log(uri),
  requiredChains: [process.env.NEXT_PUBLIC_WEB3AUTH_CHAIN_ID],
  dappUrl: 'bank.joingrapes.com',
});

export const initWeb3Onboard = init({
  //Wallets display in the order of this array
  wallets: [coinbase, injected, walletConnect],
  connect: {
    autoConnectLastWallet: true,
    // iDontHaveAWalletLink: torusModule().link,
  },

  //New version requires each chain that the app supports
  //to be defined
  chains: [
    {
      id: '0x38',
      token: 'BNB',
      label: 'BNB Smart Chain Mainnet',
      rpcUrl: CHAIN.MAINNET,
    },
    {
      id: '0x61',
      token: 'BNB',
      label: 'BNB Smart Chain Testnet',
      rpcUrl: CHAIN.TESTNET,
    },
  ],

  appMetadata: {
    name: 'Grapes',
    icon: 'https://bank.joingrapes.com/favicon-48x48.png',
    logo: 'https://bank.joingrapes.com/logo.png',
    description: 'Grapes Games',
    explore: 'https://bank.joingrapes.com/',
    /*
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: WALLET.COINBASE },
      { name: 'MetaMask', url: WALLET.METAMASK },
    ],
    */
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: false,
      minimal: false,
    },
    mobile: {
      position: 'topRight',
      enabled: false,
      minimal: true,
    },
  },
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          header: 'Welcome to Grapes',
          sidebar: {
            heading: '',
            subheading: 'Select your wallet from the options to get started',
            paragraph: 'Grapes recommends the Metamask Wallet.',
          },
        },
      },
    },
  },
});
