// Event 1 - Connect Wallet
export const gtmEvent_connect_wallet = () => {
  return {
    event: 'connect_wallet',
    location: 'Login page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
  };
};

// Event 2 - Facebook
export const gtmEvent_continue_facebook = () => {
  return {
    event: 'continue_facebook',
    location: 'Login page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
  };
};

// Event 3 - Google
export const gtmEvent_continue_google = () => {
  return {
    event: 'continue_google',
    location: 'Login page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
  };
};

// Event 4 - Apple
export const gtmEvent_continue_apple = () => {
  return {
    event: 'continue_apple',
    location: 'Login page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
  };
};

// Event 5 - Login
export const gtmEvent_login = () => {
  return {
    event: 'login',
    location: 'Login page top pane',
    description: 'Login',
    category: 'Login',
    action: 'Login',
  };
};

// Event 6 - Home
export const gtmEvent_go_to_home = () => {
  return {
    event: 'go_to_home',
    location: 'Login page top pane',
    description: 'HOME',
    category: 'Login',
    action: 'Go to Home',
  };
};

// Event 7 - Deposit
export const gtmEvent_go_to_deposit = () => {
  return {
    event: 'go_to_deposit',
    location: 'Login page top pane',
    description: 'DEPOSIT',
    category: 'Login',
    action: 'Go to Deposit',
  };
};

// Event 8 - Withdrawal
export const gtmEvent_go_to_withdrawal = () => {
  return {
    event: 'go_to_withdrawal',
    location: 'Login page top pane',
    description: 'WITHDRAWAL',
    category: 'Login',
    action: 'Go to Withdrawal',
  };
};

// Event 9 - Help
export const gtmEvent_go_to_help = () => {
  return {
    event: 'go_to_help',
    location: 'Login page top pane',
    description: 'HELP',
    category: 'Login',
    action: 'Go to Help',
  };
};

// Event 10 - Notifications
export const gtmEvent_go_to_notifications = () => {
  return {
    event: 'go_to_notifications',
    location: 'Login page top pane',
    description: 'NOTIFICATIONS',
    category: 'Login',
    action: 'Go to Notifications',
  };
};

// Event 11 - Games
export const gtmEvent_go_to_games = () => {
  return {
    event: 'go_to_games',
    location: 'Login page bottom pane',
    description: 'Games',
    category: 'Login',
    action: 'Go to Games',
  };
};

// Event 12 - How it works
export const gtmEvent_how_it_works = () => {
  return {
    event: 'how_it_works',
    location: 'Login page bottom pane',
    description: 'How it works?',
    category: 'Login',
    action: 'How it works',
  };
};

// Event 13 - Deposit (Bottom Pane)
export const gtmEvent_go_to_deposit_bottom = () => {
  return {
    event: 'go_to_deposit_bottom',
    location: 'Login page bottom pane',
    description: 'Deposit',
    category: 'Login',
    action: 'Go to Deposit',
  };
};

// Event 14 - Withdrawal (Bottom Pane)
export const gtmEvent_go_to_withdrawal_bottom = () => {
  return {
    event: 'go_to_withdrawal_bottom',
    location: 'Login page bottom pane',
    description: 'Withdrawal',
    category: 'Login',
    action: 'Go to Withdrawal',
  };
};

// Event 15 - Contact
export const gtmEvent_go_to_contact = () => {
  return {
    event: 'go_to_contact',
    location: 'Login page bottom pane',
    description: 'Contact',
    category: 'Login',
    action: 'Go to Contact',
  };
};

// Event 16 - Privacy Policy
export const gtmEvent_go_to_privacy_policy = () => {
  return {
    event: 'go_to_privacy_policy',
    location: 'Login page bottom pane',
    description: 'Privacy Policy',
    category: 'Login',
    action: 'Go to Privacy Policy',
  };
};

// Event 17 - Terms of Service
export const gtmEvent_go_to_terms_of_service = () => {
  return {
    event: 'go_to_terms_of_service',
    location: 'Login page bottom pane',
    description: 'Terms of Service',
    category: 'Login',
    action: 'Go to Terms of Service',
  };
};

// Event 18 - Terms of Service (Footnote)
export const gtmEvent_go_to_terms_of_service_footnote = () => {
  return {
    event: 'go_to_terms_of_service_footnote',
    location: 'Login page footnote',
    description: 'Terms of Service',
    category: 'Login',
    action: 'Go to Terms of Service',
  };
};

// Event 19 - Privacy Policy (Footnote)
export const gtmEvent_go_to_privacy_policy_footnote = () => {
  return {
    event: 'go_to_privacy_policy_footnote',
    location: 'Login page footnote',
    description: 'Privacy Policy',
    category: 'Login',
    action: 'Go to Privacy Policy',
  };
};

// Event 20 - OK - Successful Connection
export const gtmEvent_ok_successful_connection = () => {
  return {
    event: 'ok_successful_connection',
    location: 'Login modal',
    description: 'Connection Successful',
    category: 'Login',
    action: 'OK - Successful Connection',
  };
};

// Event 21 - OK - Unsuccessful Connection
export const gtmEvent_ok_unsuccessful_connection = () => {
  return {
    event: 'ok_unsuccessful_connection',
    location: 'Login modal',
    description: 'Connection Unsuccessful',
    category: 'Login',
    action: 'OK - Unsuccessful Connection',
  };
};

// Event 22 - Try Again - Unsuccessful Connection
export const gtmEvent_try_again_unsuccessful_connection = () => {
  return {
    event: 'try_again_unsuccessful_connection',
    location: 'Login modal',
    description: 'Connection Unsuccessful',
    category: 'Login',
    action: 'Try Again - Unsuccessful Connection',
  };
};

// Event 23 - Yes - Sign Out
export const gtmEvent_yes_sign_out = () => {
  return {
    event: 'yes_sign_out',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Connection Unsuccessful',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Yes - Sign Out',
  };
};

// Event 24 - Cancel - Sign Out
export const gtmEvent_cancel_sign_out = () => {
  return {
    event: 'cancel_sign_out',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Connection Unsuccessful',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Sign Out',
  };
};

// Event 25 - Yes - Disconnect Wallet
export const gtmEvent_yes_disconnect_wallet = () => {
  return {
    event: 'yes_disconnect_wallet',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Disconnect Wallet',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Yes - Disconnect Wallet',
  };
};

// Event 26 - Cancel - Disconnect Wallet
export const gtmEvent_cancel_disconnect_wallet = () => {
  return {
    event: 'cancel_disconnect_wallet',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Disconnect Wallet',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Disconnect Wallet',
  };
};

// Event 27 - Play Now
export const gtmEvent_play_now = () => {
  return {
    event: 'play_now',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now',
  };
};

// Event 28 - Download APK
export const gtmEvent_download_apk = () => {
  return {
    event: 'download_apk',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK',
  };
};

// Event 29 - Connect Wallet - Deposit
export const gtmEvent_connect_wallet_deposit = () => {
  return {
    event: 'connect_wallet_deposit',
    location: 'Deposit page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Deposit',
  };
};

// Event 30 - Facebook - Deposit
export const gtmEvent_continue_facebook_deposit = () => {
  return {
    event: 'continue_facebook_deposit',
    location: 'Deposit page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Deposit',
  };
};

// Event 31 - Google - Deposit
export const gtmEvent_continue_google_deposit = () => {
  return {
    event: 'continue_google_deposit',
    location: 'Deposit page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Deposit',
  };
};

// Event 32 - Apple - Deposit
export const gtmEvent_continue_apple_deposit = () => {
  return {
    event: 'continue_apple_deposit',
    location: 'Deposit page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Deposit',
  };
};

// Event 33 - Connect Wallet - Withdraw
export const gtmEvent_connect_wallet_withdraw = () => {
  return {
    event: 'connect_wallet_withdraw',
    location: 'Withdraw page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Withdraw',
  };
};

// Event 34 - Facebook - Withdraw
export const gtmEvent_continue_facebook_withdraw = () => {
  return {
    event: 'continue_facebook_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Withdraw',
  };
};

// Event 35 - Google - Withdraw
export const gtmEvent_continue_google_withdraw = () => {
  return {
    event: 'continue_google_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Withdraw',
  };
};

// Event 36 - Apple - Withdraw
export const gtmEvent_continue_apple_withdraw = () => {
  return {
    event: 'continue_apple_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Withdraw',
  };
};

// Event 37 - Connect Wallet - Notifications
export const gtmEvent_connect_wallet_notifications = () => {
  return {
    event: 'connect_wallet_notifications',
    location: 'Notifications page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Notifications',
  };
};

// Event 38 - Facebook - Notifications
export const gtmEvent_continue_facebook_notifications = () => {
  return {
    event: 'continue_facebook_notifications',
    location: 'Notifications page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Notifications',
  };
};

// Event 39 - Google - Notifications
export const gtmEvent_continue_google_notifications = () => {
  return {
    event: 'continue_google_notifications',
    location: 'Notifications page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Notifications',
  };
};

// Event 40 - Apple - Notifications
export const gtmEvent_continue_apple_notifications = () => {
  return {
    event: 'continue_apple_notifications',
    location: 'Notifications page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Notifications',
  };
};

// Event 47 - Confirm Deposit
export const gtmEvent_confirm_deposit = (currency, value) => {
  return {
    event: 'confirm_deposit',
    location: 'Deposit review transaction modal',
    description: 'Confirm',
    category: 'Deposit',
    action: 'Confirm Deposit',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 48 - Exit Deposit Confirmation
export const gtmEvent_exit_deposit_confirmation = (currency, value) => {
  return {
    event: 'exit_deposit_confirmation',
    location: 'Deposit review transaction modal',
    description: 'X',
    category: 'Deposit',
    action: 'Exit Deposit Confirmation',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 49 - Confirm Deposit Completion
export const gtmEvent_confirm_deposit_completion = (currency, value) => {
  return {
    event: 'confirm_deposit_completion',
    location: 'Deposit transaction completed modal',
    description: 'Confirm',
    category: 'Deposit',
    action: 'Confirm Deposit Completion',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 50 - Exit Deposit Completion
export const gtmEvent_exit_deposit_completion = (currency, value) => {
  return {
    event: 'exit_deposit_completion',
    location: 'Deposit transaction completed modal',
    description: 'X',
    category: 'Deposit',
    action: 'Exit Deposit Completion',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 51 - Enter Amount
export const gtmEvent_enter_amount = (currency, value) => {
  return {
    event: 'enter_amount',
    location: 'Withdraw page',
    description: '0,00',
    category: 'Withdraw',
    action: 'Enter Amount',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 52 - Confirm Withdraw
export const gtmEvent_confirm_withdraw = (currency, value) => {
  return {
    event: 'confirm_withdraw',
    location: 'Withdraw page',
    description: 'CONFIRM WITHDRAW',
    category: 'Withdraw',
    action: 'Confirm Withdraw',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 53 - Logout
export const gtmEvent_logout = () => {
  return {
    event: 'logout',
    location: 'Top pane',
    description: 'Out arrow',
    category: 'Home',
    action: 'Logout',
  };
};

// Event 54 - Discord
export const gtmEvent_discord = () => {
  return {
    event: 'discord',
    location: 'Top pane',
    description: 'join us on DISCORD',
    category: 'Home',
    action: 'Discord',
  };
};

// Event 55 - Contact email address
export const gtmEvent_contact_email_address = () => {
  return {
    event: 'contact_email_address',
    location: 'Bottom pane',
    description: 'grapegamessupport@joingrapes.com ',
    category: 'Home',
    action: 'Contact email address',
  };
};

// Event 56 - Cancel - Disconnect Wallet - X
export const gtmEvent_cancel_disconnect_wallet_x = () => {
  return {
    event: 'cancel_disconnect_wallet_x',
    location: 'Sign Out / Disconnect Wallet',
    description: 'X',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Disconnect Wallet - X',
  };
};

// Event 57 - Cancel - Sign Out - X
export const gtmEvent_cancel_sign_out_x = () => {
  return {
    event: 'cancel_sign_out_x',
    location: 'Sign Out / Disconnect Wallet',
    description: 'X',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Sign Out - X',
  };
};

// Event 58 - X - Unsuccessful Connection
export const gtmEvent_x_unsuccessful_connection = () => {
  return {
    event: 'x_unsuccessful_connection',
    location: 'Login modal',
    description: 'X',
    category: 'Login',
    action: 'X - Unsuccessful Connection',
  };
};

// Event 59 - Mobile - Redirect Deposit Page
export const gtmEvent_mobile_redirect_deposit_page = () => {
  return {
    event: 'mobile_redirect_deposit_page',
    location: 'Home page',
    description: 'Deposit',
    category: 'Home',
    action: 'Mobile - Redirect Deposit Page',
  };
};

// Event 60 - Mobile - Redirect Withdraw Page
export const gtmEvent_mobile_redirect_withdraw_page = () => {
  return {
    event: 'mobile_redirect_withdraw_page',
    location: 'Home page',
    description: 'Withdraw',
    category: 'Home',
    action: 'Mobile - Redirect Withdraw Page',
  };
};

// Event 61 - Mobile - Redirect FAQ Page
export const gtmEvent_mobile_redirect_faq_page = () => {
  return {
    event: 'mobile_redirect_faq_page',
    location: 'Home page',
    description: 'FAQ',
    category: 'Home',
    action: 'Mobile - Redirect FAQ Page',
  };
};

// Event 62 - Mobile - Redirect Privacy Policy Page
export const gtmEvent_mobile_redirect_privacy_policy_page = () => {
  return {
    event: 'mobile_redirect_privacy_policy_page',
    location: 'Home page',
    description: 'Privacy Policy',
    category: 'Home',
    action: 'Mobile - Redirect Privacy Policy Page',
  };
};

// Event 63 - Mobile - Redirect Terms of Service Page
export const gtmEvent_mobile_redirect_terms_of_service_page = () => {
  return {
    event: 'mobile_redirect_terms_of_service_page',
    location: 'Home page',
    description: 'Terms of Service',
    category: 'Home',
    action: 'Mobile - Redirect Terms of Service Page',
  };
};

// Event 64 - Type email address withdraw paypal
export const type_email_address_withdraw_paypal = () => {
  return {
    event: 'type_email_address_withdraw_paypal',
    location: 'Withdraw page',
    description: 'Type email address',
    category: 'Withdraw',
    action: 'Type email address - Withdraw PayPal',
  };
};

// #65 Select Deposit Grapes Bingo
export const select_deposit_grapes_bingo = () => {
  return {
    event: 'select_deposit_grapes_bingo',
    location: 'Deposit page',
    description: 'Deposit',
    category: 'Deposit',
    action: 'Deposit Grapes Bingo',
  };
};

// #66 Select withdraw Grapes Bingo
export const select_withdraw_grapes_bingo = () => {
  return {
    event: 'select_withdraw_grapes_bingo',
    location: 'Withdraw page',
    description: 'Withdraw',
    category: 'Withdraw',
    action: 'Withdraw Grapes Bingo',
  };
};

// #67 Deposit Package 1 for Grapes Bingo on Deposit page
export const mobile_deposit_package_1_grapes_bingo = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_grapes_bingo',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Grapes Bingo',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #68 Deposit Package 2 for Grapes Bingo on Deposit page
export const mobile_deposit_package_2_grapes_bingo = ({ currency, value }) => {
  return {
    event: 'deposit_package_2_grapes_bingo',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Grapes Bingo',
    action: 'Deposit Package 2',
    details: { currency, value },
  };
};

// #69 Deposit Package 3 for Grapes Bingo on Deposit page
export const mobile_deposit_package_3_grapes_bingo = ({ currency, value }) => {
  return {
    event: 'deposit_package_3_grapes_bingo',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Grapes Bingo',
    action: 'Deposit Package 3',
    details: { currency, value },
  };
};

// #70 Deposit Package 4 for Grapes Bingo on Deposit page
export const mobile_deposit_package_4_grapes_bingo = ({ currency, value }) => {
  return {
    event: 'deposit_package_4_grapes_bingo',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Grapes Bingo',
    action: 'Deposit Package 4',
    details: { currency, value },
  };
};

export const gtmEvent_go_to_transactions = () => {
  return {
    event: 'go_to_transactions',
    location: 'Login page top pane',
    description: 'Transactions',
    category: 'Login',
    action: 'Go to Transactions',
  };
};

// #72 - FAQ
export const gtmEvent_go_to_faq = () => {
  return {
    event: 'go_to_faq',
    location: 'Login page top pane',
    description: 'FAQ',
    category: 'Login',
    action: 'Go to FAQ',
  };
};

// #73 - LOGIN
export const gtmEvent_go_to_login = () => {
  return {
    event: 'go_to_login',
    location: 'Login page',
    description: 'LOGIN',
    category: 'Login',
    action: 'Login',
  };
};

// #119 Play Now on Home page for Bingo Grapes
export const mobile_play_now_bingo_grapes_home = () => {
  return {
    event: 'play_now_bingo_grapes_home',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now - Bingo Grapes',
  };
};

// #120 Download APK on Home page for Bingo Grapes
export const mobile_download_apk_bingo_grapes_home = () => {
  return {
    event: 'download_apk_bingo_grapes_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Bingo Grapes',
  };
};

// #121 Google Play on Home page for Bingo Grapes
export const mobile_google_play_bingo_grapes_home = () => {
  return {
    event: 'google_play_bingo_grapes_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Bingo Grapes',
  };
};

// #122 App Store on Home page for Bingo Grapes
export const mobile_app_store_bingo_grapes_home = () => {
  return {
    event: 'app_store_bingo_grapes_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Bingo Grapes',
  };
};

// #123 Play Now on Deposit page for Bingo Grapes
export const mobile_play_now_bingo_grapes_deposit = () => {
  return {
    event: 'play_now_bingo_grapes_deposit',
    location: 'Deposit page',
    description: 'PLAY NOW',
    category: 'Deposit',
    action: 'Play Now - Bingo Grapes',
  };
};

// #124 Download APK on Deposit page for Bingo Grapes
export const mobile_download_apk_bingo_grapes_deposit = () => {
  return {
    event: 'download_apk_bingo_grapes_deposit',
    location: 'Deposit page',
    description: 'DOWNLOAD APK',
    category: 'Deposit',
    action: 'Download APK - Bingo Grapes',
  };
};

// #125 Google Play on Deposit page for Bingo Grapes
export const mobile_google_play_bingo_grapes_deposit = () => {
  return {
    event: 'google_play_bingo_grapes_deposit',
    location: 'Deposit page',
    description: 'GET IT ON Google Play',
    category: 'Deposit',
    action: 'Go to Play Store - Bingo Grapes',
  };
};

// #126 App Store on Deposit page for Bingo Grapes
export const mobile_app_store_bingo_grapes_deposit = () => {
  return {
    event: 'app_store_bingo_grapes_deposit',
    location: 'Deposit page',
    description: 'Download on the App Store',
    category: 'Deposit',
    action: 'Go to App Store - Bingo Grapes',
  };
};

// #127 Play Now on Withdraw page for Bingo Grapes
export const mobile_play_now_bingo_grapes_withdraw = () => {
  return {
    event: 'play_now_bingo_grapes_withdraw',
    location: 'Withdraw page',
    description: 'Play Now',
    category: 'Withdraw',
    action: 'Play Now - Bingo Grapes',
  };
};

// #128 Confirm Confirm Withdraw
export const review_withdrawal_confirm = (currency, value) => {
  return {
    event: 'review_withdrawal_confirm',
    location: 'Withdraw review transaction modal',
    description: 'Confirm',
    category: 'Withdraw',
    action: 'Confirm Withdraw',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// #129 Cancel Withdraw Confirmation
export const review_withdrawal_cancel = (currency, value) => {
  return {
    event: 'review_withdrawal_cancel',
    location: 'Withdraw review transaction modal',
    description: 'X',
    category: 'Withdraw',
    action: 'Cancel Withdraw Confirmation',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// #130 Enter Withdraw Amount
export const gtmEvent_enter_withdraw_amount = (currency, value) => {
  return {
    event: 'enter_amount',
    location: 'Withdraw page',
    description: 'Enter Amount',
    category: 'Withdraw',
    action: 'Enter Withdraw Amount',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// #131 Great - Withdraw Confirmation
export const gtmEvent_withdraw_great = () => {
  return {
    event: 'withdrawal_completed_great',
    location: 'Withdraw completed transaction modal',
    description: 'Great',
    category: 'Withdraw',
    action: 'Great Withdraw Completion',
  };
};

// #132 Auto Login
export const gtmEvent_auto_login = () => {
  return {
    event: 'auto_login',
    location: 'Login page',
    description: 'Auto Login',
    category: 'Login',
    action: 'Auto Login',
  };
};

export const gtmEvent_change_currency_deposit = (currency) => {
  return {
    event: 'change_currency_deposit',
    location: 'Deposit page',
    description: 'Change Currency',
    category: 'Deposit',
    action: 'Switch Currency',
    label: currency,
  };
};

export const gtmEvent_change_currency_withdraw = (currency) => {
  return {
    event: 'change_currency_withdraw',
    location: 'Withdraw page',
    description: 'Change Currency',
    category: 'Withdraw',
    action: 'Switch Currency',
    label: currency,
  };
};

export const gtmEvent_link_wallet_deposit = () => {
  return {
    event: 'gtmEvent_link_wallet_deposit',
    location: 'Deposit',
    description: 'Link Wallet',
    category: 'Deposit',
    action: 'Link Wallet',
  };
};

export const gtmEvent_link_wallet_withdrawal = () => {
  return {
    event: 'gtmEvent_link_wallet_withdrawal',
    location: 'Withdrawal',
    description: 'Link Wallet',
    category: 'Withdrawal',
    action: 'Link Wallet',
  };
};

// unlink wallet
export const gtmEvent_unlink_wallet_deposit = () => {
  return {
    event: 'gtmEvent_unlink_wallet_deposit',
    location: 'Deposit',
    description: 'Unlink Wallet',
    category: 'Deposit',
    action: 'Unlink Wallet',
  };
};

export const gtmEvent_unlink_wallet_withdrawal = () => {
  return {
    event: 'gtmEvent_unlink_wallet_withdrawal',
    location: 'Withdrawal',
    description: 'Unlink Wallet',
    category: 'Withdrawal',
    action: 'Unlink Wallet',
  };
};

export const gtmEvent_faq_click_question = (question) => {
  return {
    event: 'faq_click_question',
    location: 'FAQ page',
    description: 'Click Question',
    category: 'FAQ',
    action: 'Click Question',
    link_text: question,
  };
};

export const gtmEvent_faq_click_question_extended = (question) => {
  return {
    event: 'faq_click_question_extended',
    location: 'FAQ page',
    description: 'Click Question Extended',
    category: 'FAQ',
    action: 'Click Question Extended',
    label: question,
  };
};

export const review_deposit_confirm = (currency, value) => {
  return {
    event: 'review_deposit_confirm',
    location: 'Deposit review transaction modal',
    description: 'Confirm',
    category: 'Deposit',
    action: 'Confirm Deposit',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};
