// AppButton component
const AppButton = ({ prepIcon, variant = '', size = '', className = '', ...rest }) => {
  // destructuring props

  // context hooks

  // state

  // effect

  // queries

  // other variables/functions/handlers

  // render
  return (
    <button className={`button ${variant} button-${size}`} {...rest}>
      {rest.children}
    </button>
  );
};

// export
export default AppButton;
