const { create } = require('zustand');
import HansoloService from '@/utils/service/hansolo';

const useConstantStore = create((set) => ({
  packageMap: [],
  exChangeRate: 0,
  setPackageMap: (value) => set({ packageMap: value }),
  setExchangeRate: (value) => set({ exChangeRate: value }),
  getExchangeRate: async ({ currency }) => {
    const exchangeRate = await HansoloService.getExchangeRate({ currency });
    set({ exChangeRate: exchangeRate?.data?.data });
  },
  getPackageMap: async (value) => {
    const packages = await HansoloService.getDepositAmounts();
    set({ packageMap: packages.data?.data?.[value.app] });
  },
}));

export default useConstantStore;
