import closeIcon from '@/assets/close(mobile).png';
import discordLogo from '@/assets/discord.png';
import hamburgerIcon from '@/assets/hamburger(mobile).png';
import logo from '@/assets/logo2.png';
import { GTMContext } from '@/context/GTMContext';
import useAccountStore from '@/store/useAccountStore';
import useModalStore from '@/store/useModalStore';
import useWeb3AuthStore from '@/store/useWeb3AuthStore';
import {
  gtmEvent_discord,
  gtmEvent_go_to_deposit,
  gtmEvent_go_to_faq,
  gtmEvent_go_to_help,
  gtmEvent_go_to_home,
  gtmEvent_go_to_login,
  gtmEvent_go_to_transactions,
  gtmEvent_go_to_withdrawal,
  gtmEvent_logout,
} from '@/utils/GTMFunctions';
import { useMediaQuery } from '@/utils/hooks/useMediaQuery';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import sign_out from '../../assets/sign_out.svg';
import Button from '../Button';
import LogoutModal from '../Modal/LogoutModal';

// Navbar component
const Navbar = (props) => {
  // destructuring props

  // context hooks
  const { setDataLayer } = useContext(GTMContext);
  const isTablet = useMediaQuery('(max-width: 1200px)');
  const loggedIn = useAccountStore((state) => state.loggedIn);
  const email = useWeb3AuthStore((state) => state.email);
  const setLogOut = useModalStore((state) => state.setLogOut);
  const logOut = useModalStore((state) => state.logOut);
  const isAdmin = useAccountStore((state) => state.isAdmin);

  const router = useRouter();
  // state
  const [showMenu, setShowMenu] = useState(false);
  // effect

  // queries

  // other variables/functions/handlers

  const NavLinks = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'FAQ',
      path: '/faq',
    },
  ];

  useEffect(() => {
    const overallPage = document.getElementById('overAllPage');
    if (overallPage) {
      overallPage.style.overflow = showMenu ? 'hidden' : 'auto';
    }
  }, [showMenu]);

  // if show menu open and isn't tablet, close menu
  useLayoutEffect(() => {
    if (showMenu && !isTablet) {
      setShowMenu(false);
    }
  }, [isTablet]);

  const showEmail = email && loggedIn;

  const discordLink = 'https://discord.com/invite/joingrapes';

  const handleDiscordClick = () => {
    setDataLayer(gtmEvent_discord());
    window.open(discordLink, '_blank');
    setShowMenu(false);
  };

  const handleMenuClick = (linkName) => {
    setShowMenu(false);
    switch (linkName) {
      case 'HOME':
        setDataLayer(gtmEvent_go_to_home());
        break;
      case 'Deposit':
        setDataLayer(gtmEvent_go_to_deposit());
        break;
      case 'Withdraw':
        setDataLayer(gtmEvent_go_to_withdrawal());
        break;
      case 'HELP':
        setDataLayer(gtmEvent_go_to_help());
        break;
      case 'Transactions':
        setDataLayer(gtmEvent_go_to_transactions());
        break;
      case 'FAQ':
        setDataLayer(gtmEvent_go_to_faq());
        break;
      case 'LOGIN':
        setDataLayer(gtmEvent_go_to_login());
        break;
      default:
        setDataLayer(gtmEvent_go_to_home());
        break;
    }
  };
  // render
  return (
    <>
      {showMenu && <div className="darkBG" onClick={() => setShowMenu(false)} />}
      <div className={`navbar ${showMenu && 'navbar--extended'}`}>
        <div className="navbar__menu">
          {showMenu && <Image className="close" onClick={handleMenuClick} width={30} src={closeIcon} alt="sign out" />}
          <div className="navbar__menu-item">
            {!showMenu && (
              <div className="navbar__menu-item_logo">
                <Link onClick={() => handleMenuClick('HOME')} href="/">
                  <Image width={150} src={logo} alt="logo" />
                </Link>
              </div>
            )}
            {!(isTablet && !showMenu) && (
              <div className="navbar__menu-item_links">
                {NavLinks.map((link, index) =>
                  link.name === 'NOTIFICATIONS' ? null : (
                    <Link
                      className={`${router.pathname === link.path ? 'active' : showMenu ? 'responsive' : ``}`}
                      onClick={() => handleMenuClick(link.name)}
                      href={link.path}
                      key={index}
                    >
                      {link.name}
                    </Link>
                  )
                )}
                {!(isTablet && !showMenu) && loggedIn && isAdmin ? (
                  <Link
                    className={`${router.pathname === '/admin' ? 'active' : showMenu ? 'responsive' : ``}`}
                    href="/admin"
                  >
                    ADMIN
                  </Link>
                ) : (
                  <Image onClick={handleDiscordClick} width={100} src={discordLogo} alt="discord join us" />
                )}
              </div>
            )}
          </div>
          {showEmail && !showMenu && loggedIn && (
            <div className="navbar__menu-item-address">
              <div className="navbar__menu-item-address">
                <div className="navbar__menu-item_address-address">{email}</div>
              </div>
              <div className="navbar__menu-item-address-subtitle">Email Address</div>
            </div>
          )}
          {showEmail && showMenu && (
            <div className="navbar__menu-item">
              <div className="navbar__menu-item">
                <div className="navbar__menu-item_address-address">{email}</div>
              </div>
            </div>
          )}
          {!isTablet || showMenu ? (
            <div className="navbar__menu-item">
              {!loggedIn ? (
                <div className="navbar__menu-item-connect">
                  <Link onClick={() => handleMenuClick('LOGIN')} href="/login">
                    <Button>LOGIN</Button>
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setDataLayer(gtmEvent_logout());
                    setLogOut(true);
                    setShowMenu(false);
                  }}
                  className="navbar__menu-item__disconnect"
                >
                  {showMenu && <p>SIGN OUT</p>}
                  <Image src={sign_out} alt="sign out" />
                </div>
              )}
            </div>
          ) : (
            <Image onClick={() => setShowMenu(!showMenu)} width={35} src={hamburgerIcon} alt="sign out" />
          )}
        </div>
      </div>
      <LogoutModal isOpen={logOut} onClose={() => setLogOut(false)} />
    </>
  );
};

// export
export default Navbar;
