import ConnectWallet from '@/components/ConnectWallet';
import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';
import { GTMContextProvider } from '@/context/GTMContext';
import '@/styles/globals.scss';
import { useConnectWallet } from '@web3-onboard/react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
const RenderStore = dynamic(() => import('@/components/RenderStore'), { ssr: false });

export default function App({ Component, pageProps }) {
  const [{ wallet }, connect] = useConnectWallet();

  ConnectWallet({ wallet, connect });

  return (
    <>
      <Head>
        <title>Grapes</title>
        <meta name="description" content="Grapes" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/logo.ico" />
        <meta property="og:title" content="Grapes Gaming Arcade" />
        <meta
          property="og:description"
          content="Unlock your gaming skills and win big at Grapes! Challenge yourself and thrive against others. Start playing and winning now!"
        />
        <meta property="og:image" content="https://bank.joingrapes.com/web_preview.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Unlock your gaming skills and win big at Grapes Challenge yourself and thrive against others. Start playing and winning now!"
        />
        <meta name="twitter:title" content="Grapes Gaming Arcade" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:creator" content="" />
      </Head>
      <GTMContextProvider>
        <div className="app">
          <div className="circle"></div>
          <div className="content" id="overAllPage">
            <Navbar />
            <main>
              <Component {...pageProps} />
            </main>
            <Footer />
            <RenderStore />
          </div>
        </div>
      </GTMContextProvider>
    </>
  );
}
