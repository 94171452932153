/* eslint-disable react-hooks/rules-of-hooks */
import BrowserStorageService from '@/utils/service/browserStorage';
import hansolo from '@/utils/service/hansolo';
import { create } from 'zustand';
import useConstantStore from '../useConstantStore';
import useLoadingStore from '../useLoadingStore';
import useModalStore from '../useModalStore';
import useWeb3AuthStore from '../useWeb3AuthStore';

const useAccountStore = create((set, get) => ({
  account: null,
  accountType: null,
  walletPublicKey: null,
  walletAddress: null,
  accessToken: null,
  isAdmin: false,
  loggedIn: false,
  balance: null,
  transactions: [],
  balanceUpdated: false,
  apps: null,
  selectedGameId: {
    id: null,
    app: '2cHCxmLoPnWJjo2uFmWjMBFmpRE',
  },
  autoLogin: false,
  currencies: null,
  userCurrencies: null,
  selectedCurrency: null,
  balanceObject: null,
  isUserDeposited: false,
  selectedChainName: null,
  setSelectedChainName: (selectedChainName) => set({ selectedChainName }),
  setIsUserDeposited: (isUserDeposited) => set({ isUserDeposited }),
  setBalanceObject: (balanceObject) => set({ balanceObject }),
  setSelectedCurrency: (selectedCurrency) => set({ selectedCurrency }),
  setAutoLogin: (autoLogin) => set({ autoLogin }),
  setSelectedGameId: (selectedGameId) => set({ selectedGameId }),
  setApps: (apps) => set({ apps }),
  setBalanceUpdated: (balanceUpdated) => set({ balanceUpdated }),
  setTransactions: (transactions) => set({ transactions }),
  setBalance: (balance) => set({ balance }),
  setLoggedIn: (loggedIn) => set({ loggedIn }),
  setAccessToken: (accessToken) => set({ accessToken }),
  setAccountType: (accountType) => set({ accountType }),
  setAccount: (account) => set({ account }),
  setWalletPublicKey: (walletPublicKey) => set({ walletPublicKey }),
  setWalletAddress: (walletAddress) => set({ walletAddress }),
  setIsAdmin: (isAdmin) => set({ isAdmin }),
  getBalance: async () => {
    const setLoading = useLoadingStore.getState().setLoading;
    const walletAddress = get().walletAddress;
    const setBalance = get().setBalance;
    const setBalanceObject = get().setBalanceObject;
    const setBalanceUpdated = get().setBalanceUpdated;
    const setIsUserNotRegistered = useModalStore.getState().setIsUserNotRegistered;
    try {
      const balanceResp = await hansolo.getBalance('web3auth_binance', walletAddress);
      if (balanceResp?.data) {
        const balancesArray = Object.keys(balanceResp?.data?.balances).map((key) => {
          return {
            id: key,
            soft_balance: balanceResp?.data.balances[key].soft_balance,
            hard_balance: balanceResp?.data.balances[key].hard_balance,
          };
        });
        const newBalanceArr = balancesArray.filter((balance) => balance.id !== '2fXaLpcTNvnKT7mZnm0Nt30K2wy');
        setBalance(newBalanceArr);
        setBalanceObject(balanceResp?.data?.balances);
        setBalanceUpdated(true);
        return balancesArray;
      }
      setLoading(false);
      return balanceResp;
    } catch (error) {
      setBalanceUpdated(true);
      console.error('Error getting balance', error);
      if (error?.response?.status === 404) {
        setIsUserNotRegistered(true);
        setBalance({ userNotRegistered: true });
        return { userNotRegistered: true };
      }
    }
  },
  getProjectApps: async () => {
    try {
      const apps = await hansolo.getApps();
      get().setApps(apps?.data?.data);
    } catch (error) {
      console.error('Error getting project apps', error);
    }
  },
  logOutAccount: async () => {
    const web3auth = useWeb3AuthStore.getState().web3auth;
    const setLogOut = useModalStore.getState().setLogOut;
    const setPackageMap = useConstantStore.getState().setPackageMap;
    if (web3auth && web3auth.status === 'connected') {
      await web3auth.logout();
    }
    BrowserStorageService.clearAccessToken();
    setPackageMap([]);
    set({
      accessToken: null,
      accountType: null,
      account: null,
      walletPublicKey: null,
      walletAddress: null,
      isAdmin: false,
      loggedIn: false,
      balance: null,
      selectedGameId: null,
    });
    setLogOut(false);
  },
  fetchTransactions: async () => {
    const setLoading = useLoadingStore.getState().setLoading;
    setLoading(true);
    const setTransactions = get().setTransactions;
    const accountType = get().accountType;
    const walletAddress = useAccountStore.getState().walletAddress;
    const transactionsResp = await hansolo.getTransactionsHistory(accountType, walletAddress);
    setTransactions(transactionsResp?.data?.data);
    setLoading(false);
    return transactionsResp.data;
  },
  getAdminTransactions: async () => {
    const setLoading = useLoadingStore.getState().setLoading;
    setLoading(true);
    const accountType = useAccountStore.getState().accountType;
    const walletAddress = useAccountStore.getState().walletAddress;
    const transactionsResp = await hansolo.getTransaction(accountType, walletAddress);
    setLoading(false);
    return transactionsResp?.data?.data;
  },
  getCurrencies: async () => {
    try {
      const currencies = await hansolo.getCurrencies();
      set({ currencies: currencies?.data?.data });
    } catch (error) {
      console.error('Error getting currencies', error);
    }
  },
  getUserCurrencies: async () => {
    try {
      const walletAddress = get().walletAddress;
      const userCurrencies = await hansolo.getUserCurrencies('web3auth_binance', walletAddress);
      set({ userCurrencies: userCurrencies?.data });
    } catch (error) {
      console.error('Error getting user currencies', error);
    }
  },
}));

export default useAccountStore;
