// Button component
const Button = ({ variant = '', size = '', className = '', ...rest }) => {
  // destructuring props

  // context hooks

  // state

  // effect

  // queries

  // other variables/functions/handlers

  // render
  return (
    <button className={variant ? `button ${variant}` : 'button'} {...rest}>
      <div className="text">{rest.children}</div>
    </button>
  );
};

// export
export default Button;
