import { GTMContext } from '@/context/GTMContext';
import {
  gtmEvent_contact_email_address,
  gtmEvent_go_to_contact,
  gtmEvent_go_to_privacy_policy,
  gtmEvent_go_to_terms_of_service,
  gtmEvent_how_it_works,
} from '@/utils/GTMFunctions';
import Link from 'next/link';
import { useContext } from 'react';

// Footer component
const Footer = (props) => {
  // destructuring props

  // context hooks
  const { setDataLayer } = useContext(GTMContext);
  // state

  // effect

  // queries

  // other variables/functions/handlers

  // render
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content-logo">
          <img src="/logo2.png" alt="logo" />
        </div>
        <div className="footer__content-links">
          <div className="footer__content-links-item">
            <Link
              onClick={() => setDataLayer(gtmEvent_how_it_works())}
              href="/faq"
              className="footer__content-links-item_link"
            >
              FAQ
            </Link>
          </div>
          <div className="footer__content-links-item">
            <a
              onClick={() => setDataLayer(gtmEvent_go_to_contact())}
              href="mailto:grapegamessupport@joingrapes.com "
              className="footer__content-links-item_link"
            >
              Contact
            </a>
            <Link
              onClick={() => setDataLayer(gtmEvent_go_to_privacy_policy())}
              href="/privacy-policy"
              className="footer__content-links-item_link"
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() => setDataLayer(gtmEvent_go_to_terms_of_service())}
              href="/terms-of-service"
              className="footer__content-links-item_link"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom-content">
          <div className="footer__bottom-content-item">Copyright 2023 •</div>
          <a
            onClick={() => setDataLayer(gtmEvent_contact_email_address())}
            href="mailto:grapegamessupport@joingrapes.com "
            className="footer__bottom-content-item"
          >
            grapegamessupport@joingrapes.com
          </a>
        </div>
      </div>
    </div>
  );
};

// export
export default Footer;
