/* eslint-disable @next/next/no-assign-module-variable */
import { Wallet } from 'ethers';

const loadRPC = async () => {
  const module = await import('@/utils/Web3Auth/web3RPC');
  return module.default;
};

const getAddress = async (provider) => {
  if (!provider) {
    console.log('provider not initialized yet', provider);
    return;
  }
  const RPC = await loadRPC();
  const rpc = new RPC(provider);
  const accounts = await rpc.getAccounts();
  return accounts[0];
};

const signMessage = async (challenge, provider) => {
  if (!provider) {
    console.log('provider not initialized yet', provider);
    return;
  }
  const RPC = await loadRPC();
  const rpc = new RPC(provider);
  const privateKey = await rpc.getPrivateKey();
  const wallet = new Wallet(privateKey);
  const signature = await wallet.signMessage(challenge);
  return signature;
};

const compareArrays = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const abbrNum = (number) => {
  var abbrev = ['k', 'm', 'b', 't'];
  for (var i = abbrev.length - 1; i >= 0; i--) {
    var size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      let roundedNumber = Math.round((number / size) * 10) / 10;
      return `${roundedNumber}${abbrev[i]}`;
    }
  }
  return number;
};

export { abbrNum, compareArrays, getAddress, signMessage };
