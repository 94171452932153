const { create } = require('zustand');

const useModalStore = create((set) => ({
  loginWalletError: false,
  logOut: false,
  depositError: false,
  isUserNotRegistered: false,
  confirmPaypalWithdrawModal: false,
  completedPaypalWithdraw: false,
  showUserNotRegistered: false,
  ConfirmCryptoWithdrawModal: false,
  CompletedCryptoWithdraw: false,
  withdrawError: false,
  withdrawToManyRequest: false,
  completedCryptoDepositAmount: null,
  lowBalanceError: false,
  accountChangedModal: false,
  dappBrowser: false,
  setDappBrowser: (value) => set({ dappBrowser: value }),
  setAccountChangedModal: (value) => set({ accountChangedModal: value }),
  setLowBalanceError: (value) => set({ lowBalanceError: value }),
  setCompletedCryptoDepositAmount: (value) => set({ completedCryptoDepositAmount: value }),
  setWithdrawToManyRequest: (value) => set({ withdrawToManyRequest: value }),
  setWithdrawError: (value) => set({ withdrawError: value }),
  setCompletedCryptoWithdraw: (value) => set({ CompletedCryptoWithdraw: value }),
  setConfirmCryptoWithdrawModal: (value) => set({ ConfirmCryptoWithdrawModal: value }),
  setShowUserNotRegistered: (value) => set({ showUserNotRegistered: value }),
  setCompletedPaypalWithdraw: (value) => set({ completedPaypalWithdraw: value }),
  setConfirmPaypalWithdrawModal: (value) => set({ confirmPaypalWithdrawModal: value }),
  setIsUserNotRegistered: (value) => set({ isUserNotRegistered: value }),
  setDepositError: (value) => set({ depositError: value }),
  setLogOut: (value) => set({ logOut: value }),
  setLoginWalletError: (value) => set({ loginWalletError: value }),
}));

export default useModalStore;
