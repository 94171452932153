import { confirmations, etherToWeiWithDecimals } from '@/utils/helpers';
import { Contract as ContractModule, utils as UtilsModule, providers } from 'ethers';

/*
token address from tokens
balanceOf abi
provider this is the rpc url from chains
the users crypto wallet address
decimals from tokens
*/
async function balanceOf(tokenAddress, abi, rpcUrl, cryptoWalletAddress, decimals) {
  const provider = new providers.JsonRpcProvider(rpcUrl);
  const contract = new ContractModule(tokenAddress, abi, provider);
  const value = await contract.balanceOf(cryptoWalletAddress);
  return UtilsModule.formatUnits(value, decimals);
}

/*
token address from tokens
allowance abi
provider this is the rpc url from chains
the users crypto wallet address
the bank address
decimals from tokens
*/
async function allowance(tokenAddress, abi, rpcUrl, cryptoWalletAddress, bankAddress, decimals) {
  const provider = new providers.JsonRpcProvider(rpcUrl);
  const contract = new ContractModule(tokenAddress, abi, provider);
  const allowance = await contract.allowance(cryptoWalletAddress, bankAddress);
  return UtilsModule.formatUnits(allowance, decimals);
}

/*
amount from deposit packages
allowance is returned value from allowance function
*/
async function checkAllowance(amount, allowance) {
  return Number(amount) <= Number(allowance);
}

/*
token address from tokens
approve abi
the wallet provider ie external provider
bank address from banks
approval amount from tokens
decimals from tokens
*/
async function approve(tokenAddress, abi, walletProvider, bankAddress, approvalAmount, decimals) {
  const approval = etherToWeiWithDecimals(approvalAmount.toString(), decimals);
  const signer = walletProvider.getSigner();
  const contract = new ContractModule(tokenAddress, abi, signer);
  const tx = await contract.approve(bankAddress, approval);
  return await tx.wait(confirmations);
}

/*
deposit abi
bank address
crypto wallet provider ie external provider
deposit amount from deposit packages
web3auth wallet address
token address from tokens
decimals from tokens
*/
async function deposit(abi, bankAddress, walletProvider, amount, web3authWallet, tokenAddress, decimals) {
  const wei = etherToWeiWithDecimals(amount, decimals);
  const signer = walletProvider.getSigner();
  const contract = new ContractModule(bankAddress, abi, signer);
  const tx = await contract.deposit(web3authWallet, wei, tokenAddress);
  return await tx.wait(confirmations);
}

export { allowance, approve, balanceOf, checkAllowance, deposit };
