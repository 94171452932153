import React, { useState, useEffect, useRef } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef();

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setModalOpen(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="modal-container">
          <div className="darkBG" onClick={closeModal} />
          <div ref={modalRef} onKeyDown={handleKeyDown} className="modal">
            <div className="modal-content">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
